
import {Actions} from "@/store/enums/StoreEnums";
import {computed, defineComponent, nextTick, onBeforeMount, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import VideoPlayer from "@/views/apps/videojs/Player.vue";
import CardMachineLesson from "@/components/course/lesson/CardMachineLesson.vue";
import Tag from "@/components/common/Tag.vue";
import router from "@/router";
import type {Challenge} from "@/types/ChallengeType";
import ApiService from "@/core/services/ApiService";
import CardRecommendMachine from "@/components/course/lesson/CardRecommendMachine.vue";
import type {MachineType} from "@/types/MachineType";
import CardSubmitComment from "@/components/course/lesson/CardSubmitComment.vue";
import HacktivityWidget from "@/components/widgets/feeds/Widget6.vue";
import {UserType} from "@/types/UserType";
import CardCommentFake from "@/components/common/CardCommentFake.vue";
import {Mutations} from "@/store/enums/StoreEnums";
import {LessonType} from "@/types/LessonType";
import { initializeComponents } from '@/core/plugins/keenthemes';


export default defineComponent({
  name: "module-show",
  props: {
    id: {
      type: String,
      required: true
    },
    lesson_id: {
      type: String,
      required: false
    }
  },
  components: {
    CardCommentFake,
    HacktivityWidget,
    CardSubmitComment,
    CardRecommendMachine,
    VideoPlayer, Tag, CardMachineLesson
  },
  setup( props ) {
    const store = useStore();
    const srcVideo = ref("");
    const titleVideo = ref("");
    const descVideo = ref("");
    const tags = ref({});
    const lessonId = ref(0)
    const challenge = ref({} as Challenge);
    const machines = ref([]);
    const isStarted = ref(false);
    const showSpinner = ref(false);
    const showModule = ref(false);
    const hacktivities = ref([]);
    const hacktivitiesIsFixed = ref([]);
    const links = ref<string[]>([]);
    const attachments = ref([]);

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const user = computed<UserType>(() => store.getters.currentUser);
    const module = computed(() => store.getters.getCurrentModule);
    const currentLesson = computed<LessonType>(() => {
      const lessons = module.value.lessons;
      return lessons && lessons.length > 0 ? lessons[0] : null;
    });

    const changeVideo = async (lesson: object) => {

        await ApiService.get('academy/lessons/' + lesson['id'] + '/recommended_machine').then((response) => {
          machines.value = response.data.data;
        }).catch((error) => {
          console.log(error);
        });

        srcVideo.value = lesson['video_url'];
        titleVideo.value = lesson['title'] ;
        descVideo.value = lesson['description'];
        tags.value = lesson['metadata'];
        isStarted.value = lesson['is_started'];
        lessonId.value = lesson['id'];
        challenge.value = lesson['challenges'][0];
        store.commit(Mutations.SET_CHALLENGE, challenge.value);
        
        tags.value = lesson['tags'];
        hacktivities.value = lesson['hacktivities'];
        hacktivitiesIsFixed.value = filteredHacktivities(lesson)
        links.value = linksToArray(lesson['links']);
        attachments.value = lesson['attachments'];
    };
    const getIsStartedClass = (classIsStarted: boolean) => {
      return classIsStarted ? 'badge-light-success  border-success' :  'badge-light-warning border-warning';
    };
    const reloadData = async () => {
      try {
        await store.dispatch(Actions.GET_MODULE, props.id).then(() => {
          if(typeof(module.value.id) === 'undefined') {
            router.push('/');
          }else {
            isStarted.value = store.getters.getLessonData.is_started;
            const unwatchedLesson = module.value.lessons.find((lesson: { is_started: any; }) => !lesson.is_started);
            changeVideo(unwatchedLesson || currentLesson.value);
          }
        });
      } catch (error) {
        console.error('Error getting the course:', error);
      }
    };
    onBeforeMount(async () => {
      showSpinner.value = true;

      try {
        await store.dispatch(Actions.GET_MODULE, props.id).then(async () => {
          if(typeof(module.value.id) === 'undefined') {
            router.push('/');
          }else {
            if(props.lesson_id) {
              await store.dispatch(Actions.GET_LESSON, props.lesson_id).then(async() => {
                 await changeVideo(store.getters.getLessonData);
              });
            }else{
              const unWatchedLesson = module.value.lessons.find((lesson: { is_started: any; }) => !lesson.is_started);
              changeVideo(unWatchedLesson || currentLesson.value);
              isStarted.value = currentLesson.value.is_started;
            }
          }
          setTimeout(() => {
            showModule.value = true;
          }, 990);

        });
      } catch (error) {
        console.error('Error getting the course:', error);
      }
      showSpinner.value = false;
    });
    const getLesson = async () => {
      try {
        await store.dispatch(Actions.GET_LESSON, lessonId.value).then(() => {
          isStarted.value = store.getters.getLessonData.is_started;
          changeVideo(store.getters.getLessonData);
        });
      } catch (error) {
        console.error('Error getting the lesson:', error);
      }
    };
    const toggleWatchedLesson = async () => {
        isStarted.value = !isStarted.value;
      try {
        await store.dispatch(Actions.UPDATE_WATCHED_CHECK, {id: lessonId.value});
        await store.dispatch(Actions.GET_LESSON, lessonId.value).then(() => {
          isStarted.value = store.getters.getLessonData.is_started;
          changeVideo(store.getters.getLessonData);
        });
        await reloadData();
       
      } catch (error) {
        console.error('Error checking the lesson:', error);
      }
    };
    const handleHacktivityDelete = async () => {
      await getLesson();
    };
    const handleHacktivityAdded = async () => {
      await getLesson();
    };
    const handleFixedHacktivity = async () => {
      await reloadData();
    };

    function truncateTitle(title: string) {
      if(!title) return;
      return title.length > 50 ? title.substring(0, 50) + "..." : title;
    }

    const instanceData = computed(() => store.getters.getCurentChallenge);
    const filteredHacktivities = (lesson) => {
      return lesson.hacktivities.filter((hacktivity) => hacktivity.is_fixed)
    }

    const linksToArray= (link: string | null) =>{
      if(link === null) {
        return [];
      }else{
        return link.split(';');
      }
    }
    return {
      user,
      module,
      router,
      currentLesson,
      changeVideo,
      srcVideo,
      titleVideo,
      descVideo,
      CardMachineLesson,
      Tag,
      tags,
      challenge,
      isStarted,
      toggleWatchedLesson,
      machines,
      getIsStartedClass,
      showSpinner,
      showModule,
      hacktivities,
      lessonId,
      handleHacktivityAdded,
      truncateTitle,
      instanceData,
      handleHacktivityDelete,
      filteredHacktivities,
      handleFixedHacktivity,
      hacktivitiesIsFixed,
      links,
      currentLanguage,
      attachments
    };
  }
});
